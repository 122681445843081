import { lazy, Suspense } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import GuestGuard from 'components/GuestGuard';
import LoadingScreen from 'components/LoadingScreen';
import AdminGuard from 'components/AdminGuard';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const SetPassword = Loadable(lazy(() => import('pages/authentication/SetPassword')))
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')));

// Management pages
const Profile = Loadable(lazy(() => import('pages/dashboard/profile/index')));

// Customer pages
const CustomerList = Loadable(lazy(() => import('pages/dashboard/customers/Customers')));
const CustomerDetails = Loadable(lazy(() => import('pages/dashboard/customers/CustomerDetail')));

// Payment pages
const PaymentList = Loadable(lazy(() => import('pages/dashboard/payments/Payments')));
const PaymentDetails = Loadable(lazy(() => import('pages/dashboard/payments/PaymentDetails')));

// Employee pages
const EmployeeList = Loadable(lazy(() => import('pages/dashboard/employees/Employees')));
const EmployeeDetail = Loadable(lazy(() => import('pages/dashboard/employees/EmployeeDetail')));

// aging report
const AgingReport = Loadable(lazy(() => import('pages/dashboard/aging_report/AgingReport')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'reset-password/:token/:uid',
        element: <ResetPassword />
      },
      {
        path: 'set-password/:token',
        element: <SetPassword />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <CustomerList />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: <CustomerList />
          },
          {
            path: ':customerId',
            element: <CustomerDetails />
          },
        ]
      },
      {
        path: 'aging_report',
        children: [
          {
            path: '/',
            element: <AgingReport />
          },
          {
            path: ':paymentId',
            element: <PaymentDetails />
          }
        ]
      },
      {
        path: 'payments',
        children: [
          {
            path: '/',
            element: <PaymentList />
          },
          {
            path: ':paymentId',
            element: <PaymentDetails />
          }
        ]
      },
      {
        path: 'employees',
        children: [
          {
            path: '/',
            element: (
              <AdminGuard>
                <EmployeeList />
              </AdminGuard>
            )
          },
          {
            path: ':employeeId',
            element: <EmployeeDetail />
          }
        ]
      },
    ]
  },
  
];

export default routes;
