import type { FC, ReactNode } from 'react';
import { Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface AdminGuardProps {
  children: ReactNode;
}

const AdminGuard: FC<AdminGuardProps> = ({ children }) => {
  const { user } = useAuth();
  if (!user.is_superuser) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
