import type {FC} from 'react';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Box, Button, Card, CardContent, Container, Divider, Link, Typography} from '@mui/material';
import {LoginJWT} from 'components/authentication/login';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import {appSettings} from "../../config";
import { localRoutes } from '../../constants';

const platformIcons: any = {
  OAuth2: '/static/icons/jwt.svg',
  JWT: '/static/icons/jwt.svg'
};

const Login: FC = () => {
  const { platform } = useAuth() as any;
  const navigate = useNavigate();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleForgotPassword = () => {
    navigate(localRoutes.forgotPassword);
  };

  return (
    <>
      <Helmet>
        <title>
Login |
{' '}
          {appSettings.appName}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
                display: 'center',
                justifyContent: 'center',
                mb: 8
            }}
          >
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Log in
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto'
                    }
                  }}
                >
                  <img
                    alt="Auth platform"
                    src={platformIcons[platform]}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Box sx={{mt: 2}}>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={handleForgotPassword}
                  >
                    Forgot password
                  </Button>
              </Box>
              <Divider sx={{ my: 3 }} />
              <Link
                color="primary"
                variant="body2"
                href="https://iotec.io"
                target="_blank" 
              >
               Powered by ioTec
              </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
