import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import type { User } from '../types/user';
import {ACCESS_TOKEN} from "../constants";
import { BooleanLocale } from 'yup/lib/locale';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  user: User | null;
  message: string | "";
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
  set_password: (password: string, confirm_password: string, token: string) => Promise<void>;
  reset_password: (password: string, confirm_password: string, uid: string, token: string) => Promise<void>;
  forgot_password: (email: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    isAdmin: boolean;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    isAdmin: boolean;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type SetPasswordAction = {
  type: 'SET_PASSWORD';
  payload: {
    message: string;
  };
};

type ResetPasswordAction = {
  type: 'RESET_PASSWORD';
  payload: {
    message: string;
  };
};

type ForgotPasswordAction = {
  type: 'FORGOT_PASSWORD';
  payload: {
    message: string;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | SetPasswordAction
  | ResetPasswordAction
  | ForgotPasswordAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isAdmin: false,
  user: null,
  message: ""
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(ACCESS_TOKEN);
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SET_PASSWORD: (state: State, action: SetPasswordAction): State => {
    const { message } = action.payload;
    return {
      ...state,
      message
    }
  },
  RESET_PASSWORD: (state: State, action: ResetPasswordAction): State => {
    const { message } = action.payload;
    return {
      ...state,
      message
    }
  },
  FORGOT_PASSWORD: (state: State, action: ForgotPasswordAction): State => {
    const { message } = action.payload;
    return {
      ...state,
      message
    }
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  set_password: () => Promise.resolve(),
  forgot_password: () => Promise.resolve(),
  reset_password: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
      
        const decodedToken: User = jwt_decode(accessToken);
        const currentTime = new Date();

        const isExpired = decodedToken.exp < currentTime.getTime();

        if (isExpired) {
          setSession(accessToken);
          const response = await axios.get('/api/auth/user-config/');
          const user: User = response.data;
          const isAdmin: boolean = user.is_superuser;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              isAdmin
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              isAdmin: false
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            isAdmin: false
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    const response = await axios.post<{access: string}>('/api/auth/token/', {
      username,
      password
    });
    const { access} = response.data;
    const user: User = jwt_decode(access);
    setSession(access);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        isAdmin: user.is_superuser
      }
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post(
      '/api/auth/users/',
      {
        email,
        name,
        password
      }
    );
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };


  const set_password = async (
    password: string,
    confirm_password: string,
    token: string
  ) => {
      const response = await axios.post('/api/auth/set-password/', {
        password,
        confirm_password,
        token
      });
  
      const {message} = response.data;
      dispatch({
        type: 'SET_PASSWORD',
        payload: {
          message
        }
      });
  };

  const reset_password = async (password: string, confirm_password: string, uid: string, token: string) => {
    const response = await axios.post('/api/auth/reset-password/', {
      password,
      confirm_password,
      uid,
      token
    });

    const {message} = response.data
    dispatch({
      type: 'RESET_PASSWORD',
      payload: {
        message
      }
    });
}

const forgot_password = async (email: string) => {
    const response = await axios.post('/api/auth/forgot-password/', {
      email
    });

    const {message} = response.data
    dispatch({
      type: 'FORGOT_PASSWORD',
      payload: {
        message
      }
    });
}

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        set_password,
        reset_password,
        forgot_password,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
