import type { FC } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Avatar, Box, Divider, Drawer, Hidden, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import UsersIcon from '../../icons/Users';
import UserIcon from '../../icons/User';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import ChartSquareBar from "../../icons/ChartSquareBar";
import CreditCard from "../../icons/CreditCard";
import AssessmentIcon from '@mui/icons-material/Assessment';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const staffSections = [
  {
    title: 'Customers',
    path: '/customers',
    icon: <UsersIcon fontSize="small" />
  },
  {
    title: 'Aging Report',
    path: '/aging_report',
    icon: <AssessmentIcon fontSize="small" />
  },
  {
    title: 'Policy Payments',
    path: '/payments',
    icon: <CreditCard fontSize="small" />
  },
];

const dashboardSection = {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <ChartSquareBar fontSize="small" />
  };

const adminSections = [
  ...staffSections,
  {
    title: 'Employees',
    path: '/employees',
    icon: <UserIcon fontSize="small" />
  }
];

const sections = [
  {
    title: 'Management',
    items: null
  },
    {
    title: 'General',
    items: [
      {
        title: 'Profile',
        path: '/profile',
        icon: <UserIcon fontSize="small" />
      }
    ]
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  if (user.is_superuser) {
    sections[0].items = adminSections;
  } else {
    sections[0].items = staffSections;
  }
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden smUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar ? user.avatar : null}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {user.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden smUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
