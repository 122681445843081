import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/JWTContext';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { QueryClientProvider, QueryClient } from 'react-query';
import GlobalStyles from 'components/GlobalStyles';
import RTL from 'components/RTL';
import { gtmConfig } from './config';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createTheme } from './theme';
import useAuth from "./hooks/useAuth";
import LoadingScreen from "./components/LoadingScreen";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient();
const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  useScrollReset();

  const auth = useAuth();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <StyledEngineProvider injectFirst>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <RTL direction={settings.direction}>
            <QueryClientProvider client={queryClient}>
              <SnackbarProvider
                dense
                maxSnack={3}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <ToastContainer />
                  <GlobalStyles />
                  {auth.isInitialized ? content : <LoadingScreen />}
                </LocalizationProvider>
              </SnackbarProvider>
            </QueryClientProvider>
          </RTL>
        </ThemeProvider>
      </AuthProvider>
    </StyledEngineProvider>
  );
};

export default App;
