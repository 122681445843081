export const THEMES = {
  LIGHT: 'LIGHT'
};

export const AUTH_KEY_TOKEN = '__ri_client__token';
export const AUTH_KEY_USER = '__ri_client__user';
export const ACCESS_TOKEN = 'accessToken';

export const systemRoles = {
  ADMIN: 'ADMIN',
  SUPER: 'SUPER',
  PRIMARY: 'PRIMARY',
};

export const ioTecPayRoles = {
  COLLECTION: 'COLLECTION',
  DISBURSEMENT: 'DISBURSEMENT',
  WALLET: 'WALLET',
  MANAGER: 'MANAGER'
};

export const hasAnyRole = (user: any, roles: string[] = []): boolean => {
  const roleData = user.role || user.roles;
  const rolesList = roles.map((it) => it.toLocaleLowerCase());
  if (typeof roleData === 'string') {
    const userRole = roleData.toLocaleLowerCase();
    return rolesList.indexOf(userRole) >= 0;
  }
  const rolesData = roleData?.map((it: any) => it.toLocaleLowerCase()) ?? [];
  return rolesData.some((r: any) => rolesList.indexOf(r) >= 0);
};

export const isSystemUser = (user: any): boolean => {
  const roles = [...Object.values(systemRoles), ...Object.values(ioTecPayRoles)];
  return hasAnyRole(user, roles);
};

export const redux = {
  doLogin: 'DO_LOGIN',
  doLogout: 'DO_LOGOUT',
  doSearch: 'DO_SEARCH',
};

export const localRoutes = {
  forgotPassword: '/authentication/forgot-password',
  pending: '/pending',

  dashboard: '/dashboard',

  employees: '/employees',
  employeeDetails: '/employees/:employeeId',

  customers: '/customers',
  customerDetails: '/customers/:customerId',

  payments: '/payments',
  paymentDetails: '/payments/:paymentId',

};

export const remoteRoutes = {
  customers: `/api/customers/`,
  beneficiaries: `/api/beneficiaries/`,
  payments: `/api/payments/`,
  employees: `/api/auth/users/`,
  otp: `/api/payments/otp/`,
};
